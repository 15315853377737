import React from "react";
import Layout from "../../components/layout/layout";
import SEO from "../../components/layout/seo";
import PrivacyPolicy from "../../components/privacyPolicy";

export default function PrivacyPolicyPage() {
  return (
    <Layout>
      <SEO title="Privacy Policy Page" />
      <PrivacyPolicy />
    </Layout>
  );
}
