import React from "react";

import ReactHtmlParser from "react-html-parser";

export default function PrivacyPolicyContent({
  privacy,
  user_consent,
  children,
  users_outside_of_the_united_states,
  types_of_data_we_collect,
  use_of_your_personal_data_general_use,
  use_of_your_genetic_data,
  third_party_website,
  your_rights_regarding_your_personal_data,
  security_of_your_personal_data,
  contact_information,
  disputes,

  privacy_policy_changes,
  the_genetic_information_nondiscrimination_act,
}) {
  const converthtml = function (input) {
    var ret = input.replace(/&gt;/g, ">");
    ret = ret.replace(/&lt;/g, "<");
    ret = ret.replace(/&quot;/g, '"');
    ret = ret.replace(/&apos;/g, "'");
    ret = ret.replace(/&amp;/g, "&");
    ret = ret.replace(/&nbsp;/g, "");
    return ret;
  };

  return (
    <div className="col-12">
      {ReactHtmlParser(converthtml(privacy))}
      {ReactHtmlParser(converthtml(user_consent))}
      {ReactHtmlParser(converthtml(children))}
      {ReactHtmlParser(converthtml(users_outside_of_the_united_states))}
      {ReactHtmlParser(converthtml(types_of_data_we_collect))}
      {ReactHtmlParser(converthtml(use_of_your_personal_data_general_use))}
      {ReactHtmlParser(converthtml(use_of_your_genetic_data))}
      {ReactHtmlParser(converthtml(third_party_website))}
      {ReactHtmlParser(converthtml(your_rights_regarding_your_personal_data))}
      {ReactHtmlParser(converthtml(security_of_your_personal_data))}
      {ReactHtmlParser(converthtml(contact_information))}
      {ReactHtmlParser(converthtml(disputes))}
      {ReactHtmlParser(converthtml(privacy_policy_changes))}
      {ReactHtmlParser(
        converthtml(the_genetic_information_nondiscrimination_act)
      )}
    </div>
  );
}
