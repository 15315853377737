import React from "react";
import "./assets/style.scss";
import { useStaticQuery, graphql } from "gatsby";
import PrivacyPolicyContent from "./privacyPolicyContent";

export default function PrivacyPolicy() {
  const data = useStaticQuery(graphql`
    {
      allCosmicjsPrivacyPolicies {
        edges {
          node {
            slug
            metadata {
              privacy
              user_consent
              children
              users_outside_of_the_united_states
              types_of_data_we_collect
              use_of_your_personal_data_general_use
              use_of_your_genetic_data
              third_party_website
              your_rights_regarding_your_personal_data
              security_of_your_personal_data
              contact_information
              disputes
              privacy_policy_changes
              the_genetic_information_nondiscrimination_act
            }
          }
        }
      }
    }
  `);

  var content_privacy;

  data.allCosmicjsPrivacyPolicies.edges.map((x, y) => {
    switch (x.node.slug) {
      case "privacy-policies":
        content_privacy = x.node.metadata;
        break;
    }
  });

  return (
    <div className="terms-detail">
      <div className="content">
        <div className="row">
          <PrivacyPolicyContent
            privacy={content_privacy.privacy}
            user_consent={content_privacy.user_consent}
            children={content_privacy.children}
            users_outside_of_the_united_states={
              content_privacy.users_outside_of_the_united_states
            }
            types_of_data_we_collect={content_privacy.types_of_data_we_collect}
            use_of_your_personal_data_general_use={
              content_privacy.use_of_your_personal_data_general_use
            }
            use_of_your_genetic_data={content_privacy.use_of_your_genetic_data}
            third_party_website={content_privacy.third_party_website}
            your_rights_regarding_your_personal_data={
              content_privacy.your_rights_regarding_your_personal_data
            }
            security_of_your_personal_data={
              content_privacy.security_of_your_personal_data
            }
            contact_information={content_privacy.contact_information}
            disputes={content_privacy.disputes}
            privacy_policy_changes={content_privacy.privacy_policy_changes}
            the_genetic_information_nondiscrimination_act={
              content_privacy.the_genetic_information_nondiscrimination_act
            }
          />
        </div>
      </div>
    </div>
  );
}
